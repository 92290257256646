import './Common.css';

function Failed() {
  return (
    <div className="App">
      <header className="App-header">
      <img src="/images/close.svg" alt="close" className="image"/> 
        <p>
          Payment failed. Press close button, please go back and try again
        </p>
      </header>
    </div>
  );
}

export default Failed;
