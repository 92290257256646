import './Common.css';

function Success() {
  return (
    <div className="App">
       <header className="App-header">
        <img src="/images/check.svg" alt="close" className="image"/> 
            <p>
                Success. You may press the close button
            </p>
        </header>
    </div>
  );
}

export default Success;
